import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { FCM } from '@ionic-native/fcm/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

//import { SentryIonicErrorHandler } from './api/error-handler.service';

import { HttpModule } from '@angular/http';
import { HttpClientModule, HttpErrorResponse } from '@angular/common/http';

import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { File } from '@ionic-native/file/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';

import { Geolocation } from '@ionic-native/geolocation/ngx';

import { SortByPipe } from './sort-by.pipe';

import { registerLocaleData } from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import localeDe from '@angular/common/locales/de';

registerLocaleData(localeDe);     // de-DE

//import * as Sentry from 'sentry-cordova';
//Sentry.init({ dsn: 'https://f061beda35d54e65af8af99c9af9678b@sentry.zmart-ivent.de/10' });

@NgModule({
  declarations: [
    AppComponent,
    SortByPipe
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    IonicStorageModule.forRoot()
  ],
  providers: [
    StatusBar,
    SplashScreen,
    SortByPipe,
    Camera,
    File,
    WebView,
    FilePath,
    Geolocation,
    FCM,
    InAppBrowser,
    NativeStorage,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {provide: LOCALE_ID, useValue: 'de' }
    //,
    //{ provide: ErrorHandler, useClass: SentryIonicErrorHandler }

  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
