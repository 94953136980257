import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';

export interface Config {};

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  configUrl = '../assets/config.json';
  config_data: any = {};

  constructor(
      private http: HttpClient
  ) { }


  get_config(){
    return Observable.create(observer => {
      if(JSON.stringify(this.config_data) == "{}"){
        this.get_config_data().subscribe(config_data => {
          //save data in the storage!
          this.config_data = config_data;
          observer.next(this.config_data);
          observer.complete();
        });
      }else{
        observer.next(this.config_data);
        observer.complete();
      }
    });

  }

  get_config_data() {
    return this.http.get<Config>(this.configUrl).pipe(
        retry(20), // retry a failed request up to 20 times
        catchError(this.handleError) // then handle the error
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(error);
    }
    // return an observable with a user-facing error message
    return Observable.throw('Ein unerwarteter Fehler ist aufgetreten versuchen Sie es zu einem sp�teren Zeitpunkt.');
  }
}
