import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { ConfigService } from './api/config.service';
import { EventsService } from './api/events.service';
import { NewsService } from './api/news.service';
import { PushService } from './api/push.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public config : any = {};
  public pages = [];
  selectedIndex: number = -1;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private config_service: ConfigService,
	private events_service: EventsService,
	private news_service: NewsService,
    private push_service: PushService
  ) {

    this.config_service.get_config().subscribe(config_data => {
      this.config = config_data;
      this.pages = config_data.menu;
      this.initializeApp();
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
	  this.events_service.get_all_events(this.config.menu);
	  this.news_service.get_all_news(this.config.menu);
      this.push_service.push_notification_setup();
    });
  }

  open_extern_link(page){
    console.log("page",page);
    window.open(page.config.url, '_blank');
  }
}
