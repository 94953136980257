import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { Http } from '@angular/http';
import { map } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { AppMessageService } from './app-message.service';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  events: any = [];
  all_events: any = [];
  all_events_deduplicated: any = [];

  constructor(
    private ng_http: Http,
    private nativeStorage: Storage,
    private message_service: AppMessageService
  ) {}

  get_all_events(menu) {
    return new Promise( resolve => {
      // Empty the events array before fetching new data
      this.all_events = [];

      // Array of observables. Will be resolved using rxjs/forkJoin method
      let observables = [];

      for(let i = 0; i < menu.length; i++){
        let async_id = menu[i].id;

        if ( menu[i].type === 'events' ) {
          // Push each network request observable to the observables array
          observables.push(this.ng_http.get(menu[i].config.webservice_url, {})
              // Map the result of each observable.
              .pipe(map(res => {
                // Access the events data in the response.
                const events_array = res.json().response.events;
                // ToDo: mention why checkEventsArrayForNextEventDate
                // has to be called here.
                let allEvents = this.checkEventsArrayForNextEventDate( events_array );

                return {
                  // events.page.ts uses the menu_id to determine which
                  // data it will display.
                  'menu_id': async_id,
                  // module_id is used by events/details/details.component.ts
                  // to display a detail view using pushnotification data.
                  // The module_id for events is always 118 in active city.
                  'module_id': 118,
                  'events_data': allEvents
                }
              }))
          );
        }
      }

      // The observables are now forkJoined. This way we make sure, that every
      // network request successfully finished.
      // Also we know for sure that this.all_events was fully updated when the
      // Promise that wraps this function (get_all_events) gets resolved
      // https://www.learnrxjs.io/learn-rxjs/operators/combination/forkjoin
      forkJoin(observables)
        .subscribe(response => {
          // The map operator took care of formatting the data and creating
          // an object with all information needed (menu_id, module_id, events_data).
          // Now push each result to the all_events array.
          response.forEach( events_module => {
            this.all_events.push(events_module);
          })

          // At this point, this.all_events was successfully updated
          // Saving this.all_events to localstorage for offline functionality.
          // Offline functionality is realized by calling evens_service.get_events_from_storage
          // in events.page.ts
          this.set_events_to_storage(this.all_events);

          // Set this.all_events_deduplicated.
          this.set_all_events_deduplicated();

          // Networks requests done. Saved to localstorage. Time to resolve the promise.
          resolve(this.all_events);

        }, err => {
          // Events could not be fetched. We're probably offline. Fallback to
          // the all_events array stored in NativeStorage.
          this.get_events_from_storage().subscribe( all_events => {
            // Set all_events value from localstorage
            this.all_events = all_events;
            // Deduplicate the all_events array and populate all_events_deduplicated
            this.set_all_events_deduplicated();

          }, err => {
            console.log(err);
            // If there was even a problem with receiving the events from local
            // storage then show an error message.
            this.message_service.present_toast("Es konnten keine Veranstaltungen geladen werden. Bitte prüfen Sie Ihre Internetverbindung");
          });
        }
      );
    })
  }

  get_events(url) {
    return Observable.create(observer => {

      this.ng_http.get(url, {})
      .pipe(map(res => res.json()))
      .subscribe(response => {
        this.events = this.checkEventsArrayForNextEventDate( response.response.events );

        observer.next(this.events);
        observer.complete();
      }, err => {
        this.message_service.present_toast("Es konnten keine Veranstaltungen geladen werden. Bitte prüfen Sie Ihre Internetverbindung");

        observer.next([]);
        observer.complete();
      });
    });
  }

  checkEventsArrayForNextEventDate( eventsArray ) {
    // check event dates. If nextEventDate field not exists, get the next date
    // by from all dates and set it for each event
    eventsArray.forEach(( actEvent, actEventIndex, actEventParentArray ) => {
      if ( typeof actEvent.nextEventDate === 'undefined' ) {
        let eventNextDate = actEvent.dates[0];
        let today = new Date();
        today.setHours(0, 0, 0, 0);

        for ( let x = 0; x < actEvent.dates.length; x++ ) {
          if ( Date.parse( actEvent.dates[x].startDate ) > today.getTime() ) {
            eventNextDate = actEvent.dates[x];
            break;
          }
        }
        actEventParentArray[actEventIndex].nextEventDate = eventNextDate;
      }
    });

    return eventsArray;
  }

  get_events_from_storage() {
    return Observable.create(observer => {
      if(!this.all_events.length) {
        this.nativeStorage.get('all_events')
          .then(
            (data) => {
              console.log("[get_events_from_storage] all_events:",data);

              observer.next(data);
              observer.complete();
            },
            error => {
              observer.next([]);
              observer.complete();
            }
        );
      } else {
        observer.next(this.all_events);
        observer.complete();
      }

    });
  }

  set_events_to_storage( allEvents ) {
    this.nativeStorage.set('all_events', allEvents)
    .then((val) => {
      //console.log('Veranstaltungen gespeichert.', val);
    });
  }


  /////////////////////////////////////////////////////////////////////////////
  // Deduplication functionality for the events service all_events array
  //   This functionality is needed because parts of the application require
  //   access to events objects which are grouped by module_id. So they need an
  //   array of modules at which a module_id acts as a unique identifier and
  //   can't appear more than once.
  /////////////////////////////////////////////////////////////////////////////

  set_all_events_deduplicated() {
    // Deduplicate the all_events array by module_id and
    // save the result into all_events_deduplicated.
    // clone the all_events array to avoid side-effects to this.all_events
    let all_events_clone = this.all_events.map(x => Object.assign({}, x));
    // Assign the deduplicated array to this.all_events_deduplicated.
    this.all_events_deduplicated = this.create_deduplicated_array_by_key(all_events_clone, 'module_id');
  }

  create_deduplicated_array_by_key(all_events_array, key_name) {
    let events_grouped_by_module_id: any = [];

    all_events_array.forEach( event_element => {

      // Check if an element with the module_id of this event_element already
      // exists in events_grouped_by_module_id
      let index = events_grouped_by_module_id.findIndex( events_grouped_by_module_id_element => {
        return events_grouped_by_module_id_element.module_id == event_element.module_id;
      })

      // If index == -1 then no object with matching module_id was found in
      // the events_grouped_by_module_id array.
      if (index == -1) {
        // So we push the object onto the array
        events_grouped_by_module_id.push(event_element);
      }
      // Else an element with matching module_id was already present in
      // the events_grouped_by_module_id array
      else {
        // So we just combine the events_data of the two objects.
        // This way we make sure to have all events data of concern represented
        // in a single object and not split into several objects.
        events_grouped_by_module_id[index].events_data = [...events_grouped_by_module_id[index].events_data, ...event_element.events_data]
      }
    })

    // Now we still have to deduplicate the events_data in each object because
    // there might me events_objects that appear more than once.

    events_grouped_by_module_id.forEach( element => {

      // Deduplicate events_data array using a helper function
      element.events_data = this.deduplicate_array_of_objects(element.events_data);
    })

    return events_grouped_by_module_id;
  }

  // Helper function that deduplicates an array of objects
  // based on comparing all values present in the objects.
  // Returns the deduplicated array of objects.
  deduplicate_array_of_objects(array_of_objects) {
    return array_of_objects.filter( (value, index, array) =>
      array.findIndex( t =>
        // Comparing all values by stringifying the objects
        (JSON.stringify(t) === JSON.stringify(value)))===index)
  }

  /////////////////////////////////////////////////////////////////////////////

}
