import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { FCM } from '@ionic-native/fcm/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';

import { AppMessageService } from '../api/app-message.service';
import { ConfigService } from '../api/config.service';
import { EventsService } from '../api/events.service';
import { NewsService } from '../api/news.service';
import { PushHistoryService } from '../api/pushhistory.service';
import { UtilsService } from '../api/utils.service';


import { AlertController } from '@ionic/angular';

//https://www.typescriptlang.org/docs/handbook/interfaces.html
// Interface for a push notification topic delivered from active city API
interface PushNotificationTopic {
  topic_id: number;
  name: string;
  subscribed: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PushService {
  topics: any = [];
  // Push notification topics received from active city api.
  // They contain default values for subscribed which are overwritten by
  // local changes the user has made
  config: any = {};
  // Using a prefix for local storage in order to avoid collisin with other
  // local storage usage in the future. Topic names like "corona" are not very unique
  local_storage_subscription_status_prefix: string = "push_subscription_status_"

  constructor(
    private alertController: AlertController,
    private config_service: ConfigService,
    private events_service: EventsService,
    private fcm: FCM,
    private http: HttpClient,
    private iab: InAppBrowser,
    private message_service: AppMessageService,
    private nativeStorage: NativeStorage,
	  private news_service: NewsService,
    private pushhistory: PushHistoryService,
    private router: Router,
    private utils: UtilsService
    ) {
    // Get configuration data
    this.config_service.get_config().subscribe(config_data => {
      this.config = config_data;
    });
  }

  push_notification_setup(){
    console.log( "================== PUSH NOTIFICATION SETUP =======================" );
    const w: any = window;
    w.FCMPlugin.requestPushPermissionIOS(
      function(wasPermissionGiven) {
        console.info("Was permission given: "+wasPermissionGiven);
      },
      function(error) {
        console.error(error);
      }
    );

    // Receive token
    this.fcm.getToken().then(token => {
      console.log("[*] Received a token")
      console.log(token)
    });

    // Receive APNS(ApplePushNotificationService) token
    this.fcm.getAPNSToken().then(token => {
      console.log("[*] Received an APNS token")
      console.log(token)
    });

    // Token refresh handler
    this.fcm.onTokenRefresh().subscribe(token => {
      //backend.registerToken(token);
    });

    // Setup Notification behavior for background and foreground
    this.fcm.onNotification().subscribe(async data => {

      console.log("Notification Data", data);

      // Reloading news or events, depending on the module that is needed.
      // This way we make sure, that all the newest events or news objects
      // have been loaded into the app.
      // Distinguish between news and events to reduce unnecessary network requests.
      if ( !data.module_id && data.modul_id) {
        data.module_id = data.modul_id;
      }
      if (data.module_id) {
        await this.utils.reload_news_or_events(data.module_id);
      }

      // check notification titel. background notifications do not commit title to the app
      if ( typeof data.title === 'undefined') {
        if ( typeof data.notification_title !== 'undefined' ) {
          data.title = data.notification_title;
        } else {
          data.title = 'Kein Titel verfügbar.';
        }
      }

      // check notification body. background notifications do not commit body to the app
      if ( typeof data.body === 'undefined') {
        if ( typeof data.notification_body !== 'undefined' ) {
          data.body = data.notification_body;
        } else {
          data.body = 'Kein Text verfügbar.';
        }
      }

      // Add notification to the push history
      this.pushhistory.add_push_to_history(data);

      // Notification received in device tray (background)
      if(data.wasTapped){
        // Route to detail view
        this.utils.open_detail_view(data);
      // Notification received while app was open (foreground)
      } else {
        console.log("[+] Received Push Notification in foreground");
        console.log(data);

        this.present_news_alert(data);
      }
    });

    // Initialize saved push subscription choices from local storage
    // and receive available topics from active city.
    this.set_topics_subscription_status();
  };

  // Presents an alert with option buttons to skip or view details.
  // Expects a push_notification_item.
  // Subsequently calls the helper function "open_detail_view", which figures
  // out how to present the news or event, depending on the data that is
  // contained in the pushnotification.
  present_news_alert(push_notification_item) {
    const new_message = "Eine neue Mitteilung";
    const push_title = push_notification_item.title;
    const push_message = push_notification_item.body;

    const alert = this.alertController.create({
      header: new_message,
      //subHeader: 'Subtitle',
      message: '<h2>' + push_title + '</h2>' + '<p>' + push_message + '</p>',
      buttons: [
        {
          text: 'Später',
          role: 'cancel',
          handler: () => {
            //console.log('Später geklickt');
          }
        },
        {
          text: 'Jetzt anschauen',
          handler: () => {
            this.utils.open_detail_view(push_notification_item);
            //console.log('News aufgerufen');
          }
        }
      ]
    }).then(alert => alert.present());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Push Notification Subscriptions Functionality
  /////////////////////////////////////////////////////////////////////////////

  update_topics_from_local_storage() {
    this.topics = this.topics.map( (topic: PushNotificationTopic) => {

      // Unpack values from topic object.
      // If we just return the topic object to this.topics instead,
      // only a reference to the topic object is returned
      // which leads to a big mess.
      let { topic_id, name, subscribed } = topic;

      // Check if the user made an active choice to subscribe/unsubscribe a
      // specific topic
      this.check_topic_subscription_status(name)
        .then( subscribed_status => {

          // If the user made an active choice already
          if (subscribed_status != null) {
            // Reassign the users choice to the subscribed variable
            subscribed = subscribed_status;
          }
          // Create a new topic object
          const topic_updated =
            {topic_id: topic_id, name: name, subscribed: subscribed};
          console.log("Will return following topics")
          console.log(topic_updated);
          // Push the topic with updated subscribed status to the
          // components topics property
          return topic_updated
      });
    })
  }

  // Helper function that does two things:
    // 1. Find the subscription topic by name in this.topics
    // 2. Update the subscribed status of that topic
  update_topic_status(name: string, subscribed_status: boolean) {
    this.topics.forEach( topic => {
      if (topic.name == name) {
        console.log("Updating topic " + name);
        topic.subscribed = subscribed_status;
      }
    });
  }

  subscribe_unsubscribe_topic(toggle_event, topic_name: string) {
    // If switch was switched on then subscribe to topic
    if (toggle_event.detail.checked == true) {
      // Let firebase know we're subscribing.
      this.fcm.subscribeToTopic(topic_name);
      // Let our LocalStorage know we're subscribing
      this.nativeStorage.setItem(this.local_storage_subscription_status_prefix + topic_name, true);
      console.log("Subscribed to topic " + topic_name);

    }
    // If switch was switched off then unsubscribe from topic
    else {
      // Let firebase know we're unsubscribing
      this.fcm.unsubscribeFromTopic(topic_name);
      // Let our LocalStorage know we're unsubscribing
      this.nativeStorage.setItem(this.local_storage_subscription_status_prefix + topic_name, false);
      console.log("Unsubscribed from topic " + topic_name);

    }
  }

  set_topics_subscription_status() {
    // Receive available topics from active city.
    // Subscribe to the Observable that
    this.receive_ac_news_topics().subscribe( topics => {
      console.log("Received push topics from active city");
      console.log(topics);

      topics.forEach( (topic: PushNotificationTopic) => {

        // Unpack values from topic object.
        // If we just return the topic object to this.topics instead,
        //a reference to the object we are looping is returned
        // which leads to a big mess
        let { topic_id, name, subscribed } = topic;

        // Check if the user made an active choice to subscribe/unsubscribe a
        // specific topic
        this.check_topic_subscription_status(name)
          .then( subscribed_status => {

            // If the user made an active choice already
            if (subscribed_status != null) {
              // Reassign the users choice to the subscribed variable
              subscribed = subscribed_status;
            }
            // Create a new topic object
            const topic_updated =
              {topic_id: topic_id, name: name, subscribed: subscribed};
            console.log("Will push following topic on this.topics");
            console.log(topic_updated);
            // Push the topic with updated subscribed status to the
            // components topics property
            this.topics.push(topic_updated);
            // Subscribe/unsubscribe at firebase
            this.initialize_topic_subscription(topic_updated);
        });

      })
      //console.log("NEW_FRESHLY_RECEIVED_AC_TOPICS");
      //console.log(this.topics);
    })
  }


  // Loop through available topics (received from ac and local storage)
  // and subscribe/unsubscribe to them depending on their subscribe status

  initialize_topic_subscription(topic: any) {
    let subscribed = topic.subscribed;
    let topic_name = topic.name;
    if (subscribed == true) {
      this.fcm.subscribeToTopic(topic_name);
      console.log("Subscribed to" + topic_name);
    } else if (subscribed == false) {
      this.fcm.unsubscribeFromTopic(topic_name);
      console.log("Unsubscribed from" + topic_name);
    }
  }

  // Check subscription status of a topic by checking local storage
  // Returns either the status (true or false) if the user made a choice already
  // or returns null if the user has not made a choice about that topic yet.
  async check_topic_subscription_status(topic_name: string) {
    let subscription_status;
    try {
      subscription_status = await this.nativeStorage.getItem(this.local_storage_subscription_status_prefix + topic_name);
    } catch (error) {
      // The user did not actively make a choice about this topic
      // Return null, so that the default which is set in active city persists
      console.log("[check_topic_subscription_status] caught error while calling NativeStorage.getItem() " + error);
      subscription_status = null;
    }

    return subscription_status;
  }

  receive_ac_news_topics() {
    /* Active city returns an array like:
      [
        {"topic_id": 1, "name": "News", "subscribed": true},
        {"topic_id": 2, "name": "Corona", "subscribed": true}
      ];
    */
    return this.http.get<Object[]>(this.config.push_topics_url)
  }
  /////////////////////////////////////////////////////////////////////////////

}
