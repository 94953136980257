import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy'
})


// This pipe should be refactored.
// Every case should access its value for aDate and bDate
// through a dynamic fieldname and not harcoded.
// So instead of "a.releaseDate" access with a[field].
export class SortByPipe implements PipeTransform {
  transform(array: any, field: string , type: string, order: string): any[] {

    // Sort in ascending order by default.
    //  Dates: oldest date first, newest date last
    //  Text/String: a first z last
    if ( !order ) {
      order = 'asc';
    }

    array.sort((a: any, b: any) => {
      let aDate, bDate, field_a, field_b;
      switch (type) {

        case 'datetime':
          aDate = new Date(a[field]);
          bDate = new Date(b[field]);
          break;

        case 'date':
          aDate = new Date(a.releaseDate);
          bDate = new Date(b.releaseDate);
          break;

        case 'dates':
          aDate = new Date(a.dates[0].startDate);
          bDate = new Date(b.dates[0].startDate);
          break;

        case 'nextEventDate':
          aDate = new Date(a.nextEventDate.startDate);
          bDate = new Date(b.nextEventDate.startDate);
          break;

        case 'number':
          field_a = parseInt(a[field], 10);
          field_b = parseInt(b[field], 10);
          break;

        case 'string':
          field_a = a[field].toUpperCase();
          field_b = b[field].toUpperCase();
          break;

        default:
          break;
      }

      if (order === 'asc') {
        if (type === 'dates' || type === 'date' || type === 'nextEventDate' || type === 'datetime') {
          return aDate < bDate ? -1 : aDate > bDate ? 1 : a.title.localeCompare(b.title); // compare dates and titles
        } else if (field_a < field_b) {
          return -1;
        } else if (field_a > field_b) {
          return 1;
        } else {
          return 0;
        }
      } else if (order === 'desc') {
        if (type === 'dates' || type === 'date' || type === 'nextEventDate' || type === 'datetime') {
          return aDate > bDate ? -1 : aDate < bDate ? 1 : a.title.localeCompare(b.title); // compare dates and titles
        } else if (field_a > field_b) {
          return -1;
        } else if (field_a < field_b) {
          return 1;
        } else {
          return 0;
        }
      }
    });

    return array;
  }
}
