import { Injectable } from '@angular/core';
import { NativeStorage } from '@ionic-native/native-storage/ngx';



//https://www.typescriptlang.org/docs/handbook/interfaces.html
// Interface for a push notification topic delivered from active city API
interface PushNotificationItemHistory {
  title: string;
  body: string;
  link: string;
  record_id: number;
  module_id: number;
  datetime: Date;
}

@Injectable({
  providedIn: 'root'
})
export class PushHistoryService {
  // Array of PushHistoryItems
  push_history_items: Array<PushNotificationItemHistory> = [];
  local_storage_push_history_id = "push_history";

  constructor(
    private nativeStorage: NativeStorage,
  ) { }

  // Logic to add push notification to a push history array
  // Expects a Push Notification object as data
  async add_push_to_history(push_notification_data) {
    // Retrieve History Array from localstorage
    this.push_history_items = await this.get_localstorage_pushnotification_history();

    // Handle case that the History Array is not yet present in the locastorage
    if( this.push_history_items == undefined || this.push_history_items == null) {
      console.log("Push History Array was not defined")
      // Push history array does not exist yet.
      // Therefore write an empty array to localstorage.
      await this.set_localstorage_pushnotification_history([]);
      this.push_history_items = await this.get_localstorage_pushnotification_history();
    }

    // Workaround for title, body problem caused by android handling these
    // attributes when clicking on the pushmessage. Therefore the attributes
    // are not handed over to the app so that they need to be mentioned
    // in the data attribute again as a workaround
    // see https://stackoverflow.com/a/41458543
    let push_notification_item:PushNotificationItemHistory = {
      title: null,
      body: null,
      link: null,
      record_id: null,
      module_id: null,
      datetime: new Date()
    };

    if(push_notification_data.wasTapped) {
      // Notice usage of notification_title & notification_body
      push_notification_item.title = push_notification_data.notification_title,
      push_notification_item.body = push_notification_data.notification_body,

      push_notification_item.link = push_notification_data.link,
      push_notification_item.record_id = push_notification_data.record_id;
      push_notification_item.module_id = push_notification_data.module_id;
    }
    else {
      // Notice usage of title & body instead
      push_notification_item.title = push_notification_data.title,
      push_notification_item.body = push_notification_data.body,

      push_notification_item.link = push_notification_data.link,
      push_notification_item.record_id = push_notification_data.record_id;
      push_notification_item.module_id = push_notification_data.module_id;
    }

    // Save pushnotification to array
    this.push_history_items.push(push_notification_item);

    // PushNotification was added to history
    // Save the push history array back to localstorage
    this.set_localstorage_pushnotification_history(this.push_history_items);
  }

  // Receive Push Notification History from LocalStorage
  async get_localstorage_pushnotification_history() {
    let localstorage_push_history_array;
    try {
      localstorage_push_history_array = await this.nativeStorage.getItem(this.local_storage_push_history_id);
      console.log("[*] Loaded pushhistory array from localstorage successfully")
    } catch (error) {
      // Return null if the pushhistory array was not found in local storage
      console.log("[get_localstorage_pushnotification_history] caught error while calling NativeStorage.getItem() " + error);
      localstorage_push_history_array = null;
    }

    return localstorage_push_history_array;
  }

  async set_localstorage_pushnotification_history(push_history_array) {
    this.nativeStorage.setItem(this.local_storage_push_history_id, push_history_array)
      .then(
        () => console.log('Stored PushHistory Array!'),
        error => console.error('Error storing PushHistory Array', error)
      );
  }
}
