import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { Http } from '@angular/http';
import { map } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { AppMessageService } from './app-message.service';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  news: any = [];
  all_news: any = [];
  all_news_deduplicated: any = [];

  constructor(
    private ng_http: Http,
    private nativeStorage: Storage,
    private message_service: AppMessageService
  ) {}

  get_all_news(menu) {
    return new Promise( resolve => {
      // Empty the news array before fetching new data
      this.all_news = [];

      // Array of observables. Will be resolved using rxjs/forkJoin method
      let observables = [];

      for(let i = 0; i < menu.length; i++){
        let async_id = menu[i].id;

        if(menu[i].type == 'news'){
          // Push each network request observable to the observables array
          observables.push(this.ng_http.get(menu[i].config.webservice_url, {})
            // Map the result of each observable.
             .pipe(
                map(
                  res => {
                      // Access the news data in the response.
                      let news_array = []; // default empty array

                      // if the response have any news set the news_array to the response news
                      if( res.json().response.news ){
                          news_array = res.json().response.news;
                      }

                      return {
                          // news.page.ts uses the menu_id to determine which news
                          // data it will display.
                          'menu_id': async_id,
                          // module_id is used by news/details/details.component.ts
                          // to display a detail view using pushnotification data.
                          // Each news object in the news_array contains the module_id,
                          // so we just fetch it from the first element.
                          'module_id': news_array.length ? news_array[0].modul_id : 0, // if the news array is empty the modul_id is set to 0 to prevent system collaps
                          'news_data': news_array
                      }
                  }
                )
              )
          );
        }
      }

      // The observables are now forkJoined. This way we make sure, that every
      // network request successfully finished.
      // Also we know for sure that this.all_news was fully updated when the
      // Promise that wraps this function (get_all_news) gets resolved
      // https://www.learnrxjs.io/learn-rxjs/operators/combination/forkjoin
      forkJoin(observables)
        .subscribe(response => {
          // The map operator took care of formatting the data and creating
          // an object with all information needed (menu_id, module_id, news_data).
          // Now push each result to the all_news array.
          response.forEach( news_array => {
            this.all_news.push(news_array);
          })

          // At this point, this.all_news was successfully updated
          // Saving this.all_news to localstorage for offline functionality.
          // Offline functionality is realized by calling news_service.get_news_from_storage
          // in news.page.ts
          this.set_news_to_storage(this.all_news);

          // Set this.all_news_deduplicated.
          this.set_all_news_deduplicated();

          // Networks requests done. Saved to localstorage. Time to resolve the promise.
          resolve(this.all_news);

        }, err => {
          // News could not be fetched. We're probably offline. Fallback to
          // the all_news array stored in NativeStorage.
          this.get_news_from_storage().subscribe( all_news => {
            // Set all_news value from localstorage
            this.all_news = all_news;
            // Deduplicate the all_news array and populate all_news_deduplicated
            this.set_all_news_deduplicated();

          }, err => {
            console.log(err);
            // If there was even a problem with receiving the news from local
            // storage then show an error message.
            this.message_service.present_toast("Es konnten keine News geladen werden. Bitte prüfen Sie Ihre Internetverbindung");
          });
        }
      );
    })
  }

  get_news(url) {
    return Observable.create(observer => {

      this.ng_http.get(url, {})
      .pipe(map(res => res.json()))
      .subscribe(response => {
        this.news = response.response.news;

        observer.next(this.news);
        observer.complete();
      }, err => {
        this.message_service.present_toast("Es konnten keine News geladen werden. Bitte prüfen Sie Ihre Internetverbindung");

        observer.next([]);
        observer.complete();
      });
    });
  }

  set_news_to_storage(all_events) {
    this.nativeStorage.set('all_news', this.all_news)
      .then((val) => {
    });
  }

  get_news_from_storage() {
    return Observable.create(observer => {
      if(!this.all_news.length){
        this.nativeStorage.get('all_news')
          .then(
            (data) => {
              console.log("[get_news_from_storage] get all_news",data);

              observer.next(data);
              observer.complete();
            },
            error => {
              console.error(error);

              observer.next([]);
              observer.complete();
            }
        );
      } else {
        observer.next(this.all_news);
        observer.complete();
      }

    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deduplication functionality for the news service all_news array
  //   This functionality is needed because parts of the application require
  //   access to news objects which are grouped by module_id. So they need an
  //   array of modules at which a module_id acts as a unique identifier and
  //   can't appear more than once.
  /////////////////////////////////////////////////////////////////////////////

  set_all_news_deduplicated() {
    // Deduplicate the all_news array by module_id and
    // save the result into all_news_deduplicated.
    // clone the all_news array to avoid side-effects to this.all_news
    let all_news_clone = this.all_news.map(x => Object.assign({}, x));
    // Assign the deduplicated array to this.all_news_deduplicated.
    this.all_news_deduplicated = this.create_deduplicated_array_by_key(all_news_clone, 'module_id');
  }

  create_deduplicated_array_by_key(all_news_array, key_name) {
    let news_grouped_by_module_id: any = [];

    all_news_array.forEach( news_element => {

      // Check if an element with the module_id of this news_element already
      // exists in news_grouped_by_module_id
      let index = news_grouped_by_module_id.findIndex( news_grouped_by_module_id_element => {
        return news_grouped_by_module_id_element.module_id == news_element.module_id;
      })

      // If index == -1 then no object with matching module_id was found in
      // the news_grouped_by_module_id array.
      if (index == -1) {
        // So we push the object onto the array
        news_grouped_by_module_id.push(news_element);
      }
      // Else an element with matching module_id was already present in
      // the news_grouped_by_module_id array
      else {
        // So we just combine the news_data of the two objects.
        // This way we make sure to have all news data of concern represented
        // in a single object and not split into several objects.
        news_grouped_by_module_id[index].news_data = [...news_grouped_by_module_id[index].news_data, ...news_element.news_data]
      }
    })

    // Now we still have to deduplicate the news_data in each object because
    // there might me news_objects that appear more than once.
    news_grouped_by_module_id.forEach( element => {

      // Deduplicate news_data array using a helper function
      element.news_data = this.deduplicate_array_of_objects(element.news_data);
    })

    return news_grouped_by_module_id;
  }

  // Helper function that deduplicates an array of objects
  // based on comparing all values present in the objects.
  // Returns the deduplicated array of objects.
  deduplicate_array_of_objects(array_of_objects) {
    return array_of_objects.filter( (value, index, array) =>
      array.findIndex( t =>
        // Comparing all values by stringifying the objects
        (JSON.stringify(t) === JSON.stringify(value)))===index)
  }

  /////////////////////////////////////////////////////////////////////////////


}
