import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { AppMessageService } from '../api/app-message.service';
import { ConfigService } from '../api/config.service';
import { NewsService } from '../api/news.service';
import { EventsService } from '../api/events.service';


/*
Miscellaneous functionalities used across the application is bundled here for
the sake of not repeating ourselves.
*/

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  config: any = {};

  constructor(
    private config_service: ConfigService,
    private iab: InAppBrowser,
    private message_service: AppMessageService,
    private router: Router,
    private news_service: NewsService,
    private events_service: EventsService
  ) {
    // Get configuration data
    this.config_service.get_config().subscribe(config_data => {
      this.config = config_data;
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // Navigation & Routing functionality
  /////////////////////////////////////////////////////////////////////////////

  // Open the news or event depending on the data received.
  // 1. Try to open in the app (record_id & module_id needed)
  // 2. Fallback to open the link in browser
  // 3. No link & no record_id or module_id available,  so say sorry and do nothing
  open_detail_view(push_history_item: any) {
    const record_id = push_history_item.record_id;
    const module_id = push_history_item.module_id;
    const link = push_history_item.link;
    const ac_module_ids = this.config.ac_module_ids;

    if (record_id != null && module_id != null && this.news_or_event_exists(module_id, record_id)) {
      // Open detail view locally
      this.navigate_to_detail_view(record_id, module_id);
    } else if (link != null) {
      // Fallback to open the news in an in-app browser
      this.open_link_in_app_browser(push_history_item.link);
      // If neither module_id & record_id nor a link was submitted via the
      // pushnotification data payload, we can't open a detail view
    } else {
      this.message_service.present_toast("Bitte entschuldigen Sie, die gewünschten Inhalte sind im Moment nicht verfügbar. Bitte probieren Sie es später noch einmal.");
    }
  }

  // Opens a link in the InAppBrowser on the device
  open_link_in_app_browser(link: string) {
    const browser = this.iab.create(link);
  }

  // Routing function that routes to detail views using active city ids.
  // Of course, the detail view component has to be configured to support
  // this behavior.

  // Following names correspond with the active city naming
  //    record_id -> the objects id (e.g. news object)
  //    module_id -> the active-city modul_id (changed to english notation)
  async navigate_to_detail_view(record_id: number, module_id: number) {
    let url = '';
    // Load id array for active city modules
    const ac_module_ids = this.config.ac_module_ids;

    // News modules
    if (
      // Pressemitteilungen id -> 2
      module_id == ac_module_ids.pressemitteilungen ||
      // Aktuelles id -> 33
      module_id == ac_module_ids.aktuelles ||
      //  Bekanntmachungen id -> 34
      module_id == ac_module_ids.bekanntmachungen
      ) {
        url = '/news/details/'
    // Events module
      // Events id -> 118
    } else if (module_id == ac_module_ids.events) {
        url = '/events/details/'
    }
    const detail_url = url + record_id;

    // Passing the query parameter module_id to the NewsDetailsComponent view in
    // src/app/news/details/details.component.ts.
    // The NewsDetailsComponent is then able to filter the all_news attribute
    // of NewsService for the correct news module
    // and consequently to find the news object.
    try {
      await this.router.navigate([detail_url], { queryParams: { 'module_id': module_id } });
    }
    // If routing to the detail view using record_id and module_id
    // was not successfull, present a message to the user.
    catch(e) {
      console.log(`[-] Error routing to detail view.\n record_id: ${record_id}\n module_id: ${module_id}`)
      console.log(e)
      this.message_service.present_toast("Bitte entschuldigen Sie, die gewünschten Inhalte konnten nicht geöffnet werden.");

    }
  }
  /////////////////////////////////////////////////////////////////////////////


  /////////////////////////////////////////////////////////////////////////////
  // Other utility functions
  /////////////////////////////////////////////////////////////////////////////

  // Returns a string describing the kind of module that the module_id represent.
  get_module_type(module_id: number) {
    const ac_module_ids = this.config.ac_module_ids;
    if (
      module_id == ac_module_ids.pressemitteilungen ||
      module_id == ac_module_ids.aktuelles ||
      module_id == ac_module_ids.bekanntmachungen
    ) {
      return "news"
    }
   else if (
     module_id == ac_module_ids.events
   ) {
     return "events"
   }
  }

  // Returns true if a news or events was found.
  // Returns false otherwise.
  news_or_event_exists(module_id: number, record_id: number) {
    const module_type = this.get_module_type(module_id);
    let navigate_to_element:any = {};

    if (module_type == "news") {
      // Get news module datasets
      const filtered_news_data_by_module = this.news_service.all_news_deduplicated.filter(
        all_news_item => all_news_item.module_id == module_id
      )[0];

      // Get news
      navigate_to_element = filtered_news_data_by_module.news_data.filter(
        filtered_news_item => filtered_news_item.id == record_id
      )[0];
    }
    else if (module_type == "events") {
      // Get events module datasets
      const filtered_events_data_by_module = this.events_service.all_events_deduplicated.filter(
        all_events_item => all_events_item.module_id == module_id
      )[0];

      // Get event
      navigate_to_element = filtered_events_data_by_module.events_data.filter(
        filtered_events_item => filtered_events_item.id == record_id
      )[0];
    }

    if (navigate_to_element != null) {
      console.log(`[+] Element with module_id ${module_id} and record_id ${record_id} exists`);
      console.log(navigate_to_element);

      return true
    } else {
      console.log(`[-] Element with module_id ${module_id} and record_id ${record_id} does NOT exist`);
      console.log(navigate_to_element);

      return false
    }
  }

  // Fetches the latest news OR events from the backend depending on the module_id.
  async reload_news_or_events (module_id: number) {
    let module_type = this.get_module_type(module_id);
    if (module_type == "news") {
      console.log("[*] Reloading NEWS.....");
      // Reload all_news & all_news_deduplicated attribute in news.service.ts
      await this.news_service.get_all_news(this.config.menu);
    }
    else if (module_type = "events") {
      console.log("[*] Reloading EVENTS.....");
      // Reload all_events & all_events_deduplicated attribute in events.service.ts
      await this.events_service.get_all_events(this.config.menu);
    }
  }

  async reload_all_news_and_all_events() {
     console.log("[*] Reloading news AND events");
    await this.news_service.get_all_news(this.config.menu);
    console.log(".............");
    await this.events_service.get_all_events(this.config.menu);
  }
}
