import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';

//import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AppMessageService {

  constructor(
    private alertCtrl: AlertController,
    //private router: Router,
    private toastController: ToastController
  ) { }

  async display_message(title, message){
    const alert = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            //this.router.navigate(['/home']); //back to home page
          }
        }
      ]
    });

    await alert.present();
  }

  async present_toast(text) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000
    });
    toast.present();
  }
}
